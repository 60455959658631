"use client"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { httpLink } from "@trpc/client"
import { createTRPCReact } from "@trpc/react-query"
import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server"
import { useState } from "react"
import superjson from "superjson"

import { toast } from "../components/ui/Sonner"
import type { AppRouter } from "../server/api/root"

export function getBaseUrl() {
  if (typeof window !== "undefined") {
    // browser should use relative path
    return ""
  }

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`
}

/** A set of type-safe react-query hooks for your tRPC API. */
export const api = createTRPCReact<AppRouter>({
  overrides: {
    useMutation: {
      async onSuccess(opts) {
        await opts.originalFn()
        await opts.queryClient.invalidateQueries()
      },
    },
  },
})

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>

export function TrpcProvider(props: { children: React.ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            onError: () => {
              toast.error("Něco se pokazilo.", {
                description: "Prosím, zkuste to znovu. Pokud problém přetrvá, kontaktujte nás.",
              })
            },
          },
        },
      }),
  )

  const [trpcClient] = useState(() =>
    api.createClient({
      links: [httpLink({ url: `${getBaseUrl()}/api/trpc`, transformer: superjson })],
    }),
  )

  return (
    <api.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>
    </api.Provider>
  )
}
